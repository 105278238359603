<template>
  <b-sidebar
    id="sidebar-company-edit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
    width="85vw"
  >
    <template v-if="isShowSidebar">
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Edit Customer Information</b-col>
        </b-row>
        <div class="py-3">
          <div class="label-header">Company Information</div>
          <b-row>
            <b-col cols="12" sm="6">
              <div class="main-label">Profile Picture</div>
              <InputUploadFile
                id="profile-image"
                label="Profile Picture"
                :img="form.picture"
                v-model="form.picture"
                :ImageName="form.picture_file_name"
                textUpload="Upload Picture"
                @setFileName="(val) => (form.picture_file_name = val)"
                :dateTime="$moment()"
                @deleteImage="deleteImage('picture')"
              />
            </b-col>
            <b-col cols="12" sm="6">
              <div class="main-label">Identification Card Picture</div>

              <InputUploadFile
                id="id-card-image"
                label="Identification Card Profile"
                :img="form.id_card_image"
                v-model="form.id_card_image"
                :ImageName="form.id_card_file_name"
                :dateTime="$moment()"
                @setFileName="(val) => (form.id_card_file_name = val)"
                @deleteImage="deleteImage('id_card_image')"
              />
            </b-col>
            <b-col sm="6" class="mt-3">
              <div class="main-label">Company Information</div>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="company_information"
                  v-model="form.company_information"
                  :options="[
                    { value: 'จดทะเบียน', text: 'Registered' },
                    { value: 'ไม่จดทะเบียน', text: 'Not Registered' },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  @change="(val) => handleTypeChange(val, 27)"
                  name="company_information-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" sm="6">
              <InputText
                :textFloat="
                  form.company_information == 'จดทะเบียน'
                    ? 'Business Reg No. (Tax ID.)'
                    : 'Identification Number'
                "
                :placeholder="
                  form.company_information == 'จดทะเบียน'
                    ? 'Business Reg No. (Tax ID.)'
                    : 'Identification Number'
                "
                type="number"
                name="Business Reg No. (Tax ID.)"
                class="mb-2"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 13);'"
                v-model="$v.form.id_card.$model"
                :v="$v.form.id_card"
                :isValidate="$v.form.id_card.$error"
              />
            </b-col>
            <b-col cols="12" sm="6">
              <InputSelect
                title="Customer Type"
                name="Customer Type"
                :options="customerTypeList"
                valueField="name"
                textField="name"
                v-model="form.customer_type"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>

            <b-col sm="12" md="6">
              <InputSelect
                title="Business Type"
                name="Business Type"
                :options="businessTypeOption"
                isRequired
                v-model="form.company_type"
                @onDataChange="(val) => handleTypeChange(val, 28)"
                :v="$v.form.company_type"
                :isValidate="$v.form.company_type.$error"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled>
                    Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <div class="break-normal"></div>
            <b-col sm="12" md="6">
              <InputSelect
                title="Business Title"
                name="Business Title"
                :options="prefixOption"
                valueField="name"
                textField="name"
                isRequired
                v-model="form.prefix"
                :v="$v.form.prefix"
                :isValidate="$v.form.prefix.$error"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled>
                    Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <b-col cols="12" md="6" v-if="checkOtherValue()">
              <InputText
                textFloat="Title"
                placeholder="Title"
                type="text"
                name="tax-id"
                v-model="form.prefix_other"
              />
            </b-col>
            <b-col sm="12" md="6">
              <InputText
                textFloat="Business Name"
                placeholder="Business Name"
                type="text"
                name="first_name_th"
                v-model="form.first_name_th"
                isRequired
                :v="$v.form.first_name_th"
                :isValidate="$v.form.first_name_th.$error"
              />
            </b-col>
            <div class="break-normal"></div>
            <b-col sm="6">
              <div class="main-label">Branch Type</div>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="company_branch_type"
                  v-model="form.company_branch_type"
                  :options="[
                    { value: 'สำนักงานใหญ่', text: 'Head Office' },
                    { value: 'สาขา', text: 'Branch' },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  name="company_branch_type"
                  @change="changeBranchType"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="form.company_branch_type == 'สาขา'">
              <div class="d-flex" style="column-gap: 15px">
                <InputText
                  textFloat="Branch Number"
                  placeholder="Branch Number"
                  type="text"
                  name="Branch Number"
                  isRequired
                  v-model="form.company_branch_no"
                  style="flex: 1"
                  :v="$v.form.company_branch_no"
                  :isValidate="$v.form.company_branch_no.$error"
                />
                <InputText
                  textFloat="Branch Name"
                  placeholder="Branch Name"
                  type="text"
                  name="Branch Name"
                  isRequired
                  v-model="form.company_branch"
                  style="flex: 1"
                  :v="$v.form.company_branch"
                  :isValidate="$v.form.company_branch.$error"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <InputText
                textFloat="Email"
                placeholder="Email"
                type="text"
                name="email"
                v-model="$v.form.email.$model"
                :v="$v.form.email"
                :isValidate="$v.form.email.$error"
              />
            </b-col>
            <b-col cols="6">
              <InputTelephoneWithCountry
                textFloat="Primary Telephone."
                placeholder="Primary Telephone."
                :country="form.telephone_country_id"
                @set="(val) => (form.telephone_country_id = val)"
                v-model="form.telephone"
                isRequired
                :v="$v.form.telephone"
                :isValidate="$v.form.telephone.$error"
              />
            </b-col>
            <b-col cols="6">
              <InputTag
                v-model="optionalTelephone"
                :limit="parseInt(form.limit_secondary_telephone)"
              />
            </b-col>
            <b-col cols="12">
              <div class="label-header">Membership Information</div></b-col
            >
            <b-col cols="12">
              <InputSelect
                title="Membership Level"
                name="level"
                v-model="form.member_level_id"
                noPleaseSelect
                v-bind:options="memberLevelList"
                valueField="id"
                textField="name"
                @onDataChange="selectMemberLevel"
              />
            </b-col>
            <b-col cols="6" class="mb-3">
              <div class="content-between">
                <label class="main-label">Tier Start Date</label>
                <div>
                  <small>{{
                    countDuration("tier_start_date", form.tier_start_date)
                  }}</small>
                </div>
              </div>
              <div class="input-container">
                <datetime
                  type="date"
                  :input-style="styleDatetime"
                  v-model="form.tier_start_date"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-zone="Asia/Bangkok"
                  :disabled="true"
                  ref="tier_start_date"
                >
                </datetime>
                <div :class="'icon-primary text-right'">
                  <!-- @click="$refs.tier_start_date.isOpen = true" -->
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="' color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="mb-3">
              <div class="content-between">
                <label class="main-label">Tier End Date </label>
                <div>
                  <small>{{
                    countDuration("expire_date", form.expire_date, true)
                  }}</small>
                </div>
              </div>
              <div class="input-container">
                <datetime
                  type="date"
                  :input-style="styleDatetime"
                  v-model="form.expire_date"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  :min-datetime="form.tier_start_date"
                  value-zone="Asia/Bangkok"
                  ref="tier_end_date"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.tier_end_date.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <InputSelect
                title="Price Tier"
                name="level"
                v-model="form.price_tier_id"
                v-bind:options="priceTierOptions"
                valueField="id"
                textField="name"
                placeholder="Please Select"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="0"
                    >Select Price Tier
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <b-col cols="12">
              <div class="label-header">Social Media</div></b-col
            >
            <template v-for="($social, index) of socialList">
              <b-col cols="12" md="6" :key="'social-' + index">
                <InputText
                  :textFloat="$social.name"
                  :placeholder="$social.name"
                  type="text"
                  :name="$social.name"
                  v-model="$social.value"
                  :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
                />
              </b-col>
            </template>
          </b-row>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
            :disabled="isLoadingUpdateProfile"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
import InputTelephoneWithCountry from "@/components/inputs/InputTelephoneWithCountry";

import InputUploadFile from "@/components/inputs/InputUploadFile";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
const Script_checkID = (id, c) => {
  id = String(id);

  if (id == "null") {
    return true;
  }
  if (id.trim() == "") {
    return true;
  }
  let i = 0,
    sum = 0;
  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};
const telephoneFormat = (val) => {
  let validate = new RegExp(/^\d+(\-\d{1,20})?$/);

  if (validate.test(val)) return true;
  return false;
};
const TelephoneMinLength = (val, e, a) => {
  if (val.split("-")[0].length < 9 && e.telephone_country_id == 1) return false;
  return true;
  // return minLength(val);
};
export default {
  components: {
    UploadFile,
    InputText,
    InputSelect,
    MultipleRadioButton,
    InputTelephoneWithCountry,
    InputUploadFile,
  },
  props: {
    formMain: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldListMain: {
      required: true,
      type: Array,
    },
    socialList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      businessTypeOption: [],
      genderChoice: [],
      optionalTelephone: [],
      form: {
        user_guid: this.$route.params.id,
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: "",
        member_level_id: 0,
        picture: null,
        birthday: "",
        line_id: null,
        line_ref: null,
        home_address: null,
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
        id_card: "",
        prefix: "",
        user_custom_field: [],
        user_tag: [],
        telephone_country_id: 1,
        company_branch: "",
        company_branch_no: "",
      },
      fieldList: [],
      errorOptional: false,
      customerTypeList: [],
      priceTierOptions: [],
    };
  },
  validations: {
    form: {
      first_name_th: { required },
      last_name_th: {
        required: requiredIf(function (item) {
          return this.form.is_company == 0;
        }),
      },
      company_type: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      prefix: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      company_branch: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      company_branch_no: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      telephone: {
        required,
        telephoneFormat,
        TelephoneMinLength,
        // maxLength: maxLength(10),
        // minLength: minLength(9),
      },
      email: { email },
      id_card: {
        // Script_checkID,
        maxLength: maxLength(13),
        minLength: minLength(13),
        numeric,
      },
    },
  },
  mounted() {},
  computed: {},
  methods: {
    async getPriceTier() {
      const res = await this.axios("/customer/getpricetier");
     
      this.priceTierOptions = res.data.detail;
    },
    async setFields() {
      if (this.fieldList.length > 0) {
        let data = JSON.parse(JSON.stringify(this.form));
        const prefix = await this.handleTypeChange(
          this.form.company_information,
          27,
          false
        );
        if (prefix) {
          let cpf = prefix.find((el) => el.name == this.form.company_type);
          if (cpf) {
            let pf = cpf.denpendent.map((el) => {
              return { ...el, name: el.choice_dependent_name };
            });

            this.prefixOption = pf;

            if (!pf.find((el) => el.name == this.form.prefix)) {
              this.form.prefix_other = data.prefix;
              this.form.prefix = pf.find((el) => el.choice_type_id == 3).name;
            }
          }
        }
      }
    },
    handlePrefix() {
      this.$nextTick(() => {
        let male = ["เด็กชาย", "นาย"];
        let Female = ["เด็กหญิง", "นาง", "นางสาว"];

        if (male.includes(this.form.prefix)) {
          this.form.gender = "Male";
        } else if (Female.includes(this.form.prefix)) {
          this.form.gender = "Female";
        } else this.form.gender = "N/A";
      });
    },
    async show() {
      this.$v.form.$reset();
      this.fieldList = JSON.parse(JSON.stringify(this.FieldListMain));

      this.form = JSON.parse(JSON.stringify(this.formMain));
      await this.getCustomerType();
      this.getPriceTier();
      this.optionalTelephone = this.formMain.optional_telephone
        ? this.formMain.optional_telephone.split(",").filter((el) => el)
        : "";
      await this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },

    deleteImage(key) {
      this.form[key] = "";
    },
    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },
    async submitAction() {
      // await this.$nextTick(async () => await this.$emit("setForm", this.form));
      // this.formMain = JSON.parse(JSON.stringify(this.form));
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      this.updateProfileDetail();
      this.hide();
    },
    async updateProfileDetail() {
      this.$bus.$emit("showLoading");
      let payload = { ...this.form };
      payload.expire_date = payload.expire_date ? payload.expire_date : null;
      payload.social = this.socialList.map((el) => {
        return {
          id: el.id,
          social_media_type_id: el.social_media_type_id,
          value: el.value,
          user_guid: this.$route.params.id,
        };
      });
      payload.optional_telephone = this.optionalTelephone.join(",");

      if (this.checkOtherValue()) payload.prefix = this.form.prefix_other;

      await this.$store.dispatch("updateProfileCustomerReport", payload);
      const result = await this.axios.post("/customer/customer_type", {
        user_guid: this.$route.params.id,
        customer_type: this.form.customer_type,
      });
      const data = this.$store.state.report.updateProfileCustomer;
      if (data.result === 1) {
        this.successAlert().then(() => this.$emit("getData"));
      } else {
        this.warningAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    handleChange(val) {
      this.form.gender = val;
    },
    openModalPoint() {
      this.$emit("openModalPoint", 1);
    },
    openModalBranchPoint() {
      this.$emit("openModalPoint", 2);
    },

    validateOptional(val) {
      const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
      if (parseInt(this.form.limit_secondary_telephone) != 0) {
        if (
          this.optionalTelephone.length >= this.form.limit_secondary_telephone
        )
          return;
      }
      if (val.length == 10 && isNumeric(val)) {
        return val;
      }

      (this.errorOptional = true),
        setTimeout(() => {
          this.errorOptional = false;
        }, 3000);
      return;
    },
    countDuration(key, value, expire) {
      let m = this.$moment(value);
      let year, month, day, hour;
      if (expire) {
        year = m.diff(this.$moment(), "year");
        m.add(year, "year");
        month = m.diff(this.$moment(), "month");
        day = this.$moment(value).diff(this.$moment(), "day");
        hour = this.$moment(value).diff(this.$moment(), "hour");
      } else {
        year = this.$moment().diff(m, "year");
        m.add(year, "year");
        month = this.$moment().diff(m, "month");
        day = this.$moment().diff(this.$moment(value), "day");
        hour = this.$moment().diff(this.$moment(value), "hour");
      }

      if (year > 0 && month > 0)
        return `(${year}Y ${this.$moment(month, "M").format("M")}M ${
          expire ? "Remaining" : "Ago"
        })`;
      else if (year > 0 && month <= 0)
        return `(${year}Y ${expire ? "Remaining" : "Ago"})`;
      else if (year <= 0 && month > 0)
        return `(${this.$moment(month, "M").format("M")}M ${
          expire ? "Remaining" : "Ago"
        })`;
      else if (month <= 0 && day > 0)
        return `(${day}D ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else if (day <= 0)
        return `(${hour}H ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else return ``;
    },
    async handleTypeChange(val, id, clear = true) {
      let field = this.fieldList.find((el) => el.field_profile_type_id == id);

      let payload = field.field_choices.find((el) => el.name == val);

      let denpendent = this.fieldList.findIndex(
        (el) => el.field_profile_type_id == field.field_dependent
      );

      if (payload)
        if (payload.denpendent.length > 0) {
          var temp = null;
          // this.$nextTick(async () => {
          temp = JSON.parse(JSON.stringify(this.fieldList));

          temp[denpendent].field_choices = await payload.denpendent.map(
            (el) => {
              return {
                ...el,
                name: el.choice_dependent_name,
                description: el.name,
                denpendent: el.level3,
                is_display: 1,
              };
            }
          );
          this.fieldList = await JSON.parse(JSON.stringify(temp));
          if (id == 27) {
            if (clear) {
              this.form.company_type = "";
              this.form.prefix = "";
            }
            this.businessTypeOption = payload.denpendent.map(
              (el) => el.choice_dependent_name
            );
          }
          if (id == 28) {
            if (clear) {
              this.form.prefix = "";
            }

            this.prefixOption = payload.denpendent.map((el) => {
              return { ...el, name: el.choice_dependent_name };
            });
          }
          return temp[denpendent].field_choices;
          // });
        }
    },
    checkOtherValue() {
      if (this.prefixOption.length > 0) {
        let check = this.prefixOption.find((el) => el.name == this.form.prefix);

        return check && check.choice_type_id == 3;
      }
      return false;
    },
    async getCustomerType() {
      const result = await this.axios("setting/GetCustomerTypeSetting");

      this.customerTypeList = result.data.detail;
    },
    changeBranchType(val) {
      if (val == "สำนักงานใหญ่") {
        this.form.company_branch_no = "";
        this.form.company_branch = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--theme-secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
